import React from 'react'
import {Paragraph, Title, Title4, LeftSection, RightSection, Row} from "../../components/UI";
import cards from '../../images/cards.jpg'
import vip from '../../images/vip2.jpg'
import styled from "styled-components"
import locationData from "../../data/locations.json"
import {breakpoints} from "../../utils/helper"
import StorePage from "../../components/StorePage"
import Button from '../../components/Button'
import {OutboundLink} from 'gatsby-plugin-gtag'
import programs from '../../data/programs_beverwijk'

const Image = styled.img`
    width: 100%;
    height: auto;
    
    @media ${breakpoints.desktop} {
        width: 620px;
    }
`

const StyledParagraph = styled(Paragraph)`
    width: 100%;
    margin: 0 0 42px;
    
    @media ${breakpoints.tablet} {
        width: 90%;
    }
`

const RedTitle = styled(Title4)`
    color: ${props => props.theme.colors.red};
    white-space: pre-wrap;
`

const ExternalLink = styled(OutboundLink)`
    text-decoration: none;
`

const VIPRow = styled(Row)`
    flex-direction: column-reverse;
    margin-bottom: 30px;
    img {
        margin-top: 60px;
    }
    
    @media ${breakpoints.tablet} {
        flex-direction: row;
        
        img {
            margin-top: 0;
        }
    }
`

const RSection = styled(RightSection)`
    @media ${breakpoints.desktop} {
        width: 60%;
    }
`

const Beverwijk = ({location}) => (
    <StorePage data={locationData.locations[1]} location={location} programs={programs.items}>
        <VIPRow>
            <LeftSection>
                <Image src={vip} alt="Club UP member cards"/>
            </LeftSection>
            <RSection>
                <RedTitle>Car Cosmetics Centre</RedTitle>
                <Title className="padded">Boek nu</Title>
                <StyledParagraph>Het UP Car Cosmetics Centre is dé plek om jouw auto optimaal te laten verzorgen.
                    Wij zijn gespecialiseerd in het poetsen, polijsten en reinigen van alle voertuigen.</StyledParagraph>
                <ExternalLink rel="noopener" href="https://upcarwashbooking.nl/" target="_blank">
                    <Button>Plan je behandeling</Button>
                </ExternalLink>
            </RSection>
        </VIPRow>
        <Row>
            <LeftSection>
                {/*<RedTitle>Tot 25% korting</RedTitle>
                <Title className="padded">Get UP early!</Title>
                <StyledParagraph>Wees er vroeg bij, want bij ons krijg je doordeweeks tussen 8:00 en 9:00 uur UP early korting. Je betaalt dan tot maar liefst 25% minder voor je wasprogramma!</StyledParagraph>*/}
            </LeftSection>
            <RightSection>
                <Image src={cards} alt="Club UP member cards"/>
            </RightSection>
        </Row>
    </StorePage>
)

export default Beverwijk
